import React from 'react'
import { navigate } from "@reach/router"
import AggregateStoryFeed from '../AggregateStoryFeed'
import { sContext } from '../../../context'

class SearchFeed extends React.PureComponent {
  inputRef = React.createRef()
  localePath = this.context.currentLocale
  locale = this.context.currentLocale === '/fr' ? '/fr' : 'en-US'

  goToSearch(event) {
    if (event.key === 'Enter' && event.target.value !== '') {
      navigate(`/${this.localePath}/search?q=${encodeURIComponent(event.target.value)}`)
      if(typeof window !== 'undefined') {
        window.location.reload()
      }
    }
  }

  goToSearchButton(value) {
    if (value !== '') {
      navigate(`/${this.localePath}/search?q=`)
      if(typeof window !== 'undefined') {
        window.location.reload()
      }
    }
  }

  getQueryVariable(variable) {
    if(typeof window !== 'undefined') {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return decodeURI(pair[1].toLowerCase())
        }
      }
      return false
    }
  }

  componentDidMount() {
    const value = this.getQueryVariable('q')
    if (value) {
      this.inputRef.current.value = value
    }
  }

  render() {
    return (
      <div className="row searchfeed-main">
        <div className="row searchfeed-search-bar">
          <div className="searchfeed-search-icon"></div>
          <input
            ref={this.inputRef}
            className="searchfeed-search-input"
            onKeyPress={e => this.goToSearch(e)}
            role="search"
            aria-label={this.localePath === 'en' ? 'Search for Articles' : 'Rechercher des articles'} />
          <div
            className="searchfeed-search-arrow"
            onClick={() =>
              this.goToSearchButton(this.inputRef.current.value)
            } />
        </div>
        <AggregateStoryFeed isSearch />
      </div>
    )
  }
}

SearchFeed.contextType = sContext

export default SearchFeed
