import React, { Fragment } from 'react'
import { sContext } from '../context'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import CityGuides from './components/Aggregate/CityGuides'
import AggregateStoryFeed from './components/AggregateStoryFeed'
import SearchFeed from './components/Aggregate/SearchFeed'
import Ads from './components/Ads'

class Aggregate extends React.Component {
  //Render
  render() {
    const dataPath = this.context.location.pathname.split('/')[2]
    const localePath = this.context.currentLocale
    let sections = []

    const { pages } = this.props
    const isCityGuidesCategory = pages.path === 'city-guide' || pages.path === 'guides-voyage'

    if(!isCityGuidesCategory && !this.props.isNewCategory) {
      sections = this.props.pages.sections || this.props.pages.content.content.filter(v => v.nodeType === 'embedded-entry-block').map(v => v.data.target)
    }
    
    return (
      <div
        id="aggregate"
        className='container-fluid'>
        <div className="top-ad-wrapper">
          <Ads
            page='homepage'
            counter={1} />
        </div>
        <div className="main-wrapper">
          <Navigation
            navigationColor={this.props.pages.navigationColor}
            default={this.props.pages.navigationDefaultColor}
            menu={this.props.navigation}
            paths={this.props.paths || {}}/>
          <main>

            {this.props.isNewCategory && (
              <>
                <AggregateStoryFeed
                  key={'module-Is-Category'}
                  categoryPageData={this.props.pages} />
                <Ads counter={2} />
              </>
            )}

            {localePath === '/en' && dataPath.toLowerCase() === 'city-guide' && (
              <div className="aggregate-title">
                City Guides
              </div>
            )}

            {localePath === '/fr' && dataPath.toLowerCase() === 'guides-voyage' && (
              <div className="aggregate-title">
                Guides Voyage
              </div>
            )}

            {isCityGuidesCategory && (
              <CityGuides
                modules={pages.content.content}
                categoryInformation={this.props.categoryInformation}
              />
            )}
    
            {sections.map((data, index) => {
              let module = data.fields.type
                ? data.fields.type
                : data.sys.contentType.sys.id
              switch (module) {
                /** Used for the `/category/?aggTag= */
                case 'aggregate-story-feed':
                  return <AggregateStoryFeed key={'module-story' + index} />

                case 'aggregate-search-feed':
                  return (
                    <Fragment key={'module-' + index}>
                      <SearchFeed
                        id={data.sys.id} />
                      <Ads counter={2} />
                    </Fragment>
                  )

                default:
                  break
              }
              return undefined
            })}

            <Footer footer={this.props.footer} />
          </main>
        </div>
      </div>
    )
  }
}

Aggregate.contextType = sContext

export default Aggregate