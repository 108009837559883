import React, { useContext } from 'react';
import Masonry from 'react-masonry-css'
import LazyLoadImage from './LazyLoadImage'
import Fade from 'react-reveal/Fade'
import { sContext } from '../../context'
import ReactMarkdown from '../../lib/ReactMarkdown';
import AnchorTag from './AnchorTag';

const StoryFeedGrid = ({
  entries,
  hideMore
}) => {
  const localePath = useContext(sContext).currentLocale

  return (
    <div
      className='cStoryFeedGrid-wrapper'
      role='feed' >
      <div
        className={`row latest-articles ${hideMore ? 'expanded' : ''}`}
        style={{ gridAutoRows: '31px' }} >
        <Masonry
          breakpointCols={{
            default: 4,
            992: 1,
          }}
          className="my-masonry-grid"
          columnClassName="latest-article-wrapper">
          {entries.map(({ fields: {
            hasSponsor,
            path,
            title,
            subtitle,
            asset,
            articleType,
            category,
            categoryPath
          }}, i) => {
            const isAeroplan = articleType === 'Air Canada enRoute X Aeroplan Insider' ||
            articleType === 'Air Canada enRoute X Initiés Aéroplan'

            return (
              <article
                key={`StoryFeed-${i}`}
                className={`
                  latest-article-container
                  ${hasSponsor || isAeroplan ? 'is-a-sponsored' : ''}
                  ${isAeroplan ? 'is-aeroplan' : ''}
                `}
                aria-setsize={entries.length}
                aria-posinset={i + 1}
                aria-labelledby={`storyfeed-${i}`} >
                <Fade>
                  <div className="latest-article-content">
                    <div className="latest-article-item">
                      {asset && (
                        <AnchorTag
                          attributes={{ className: 'latest-link'}}
                          localePath={localePath}
                          categoryPath={categoryPath || category?.fields?.path}
                          path={path} >
                          <LazyLoadImage
                            asset={asset}
                            className="latest-image"
                            sizes={[
                              { viewport: 992, width: 350 },
                              { viewport: 0, width: 500 }
                            ]} />
                        </AnchorTag>
                      )}
                      <h3 className="latest-type">
                        {hasSponsor ? (
                          <p>{hasSponsor}</p>
                        ) : (
                          <AnchorTag
                            attributes={{ className: 'latest-link'}}
                            localePath={localePath}
                            articleType={articleType}
                            isAggTag >
                            {' '}{articleType}{' '}
                          </AnchorTag>
                        )}
                      </h3>
                      <AnchorTag
                        attributes={{ className: 'latest-link'}}
                        localePath={localePath}
                        categoryPath={categoryPath || category?.fields?.path}
                        path={path} >
                        <div className="latest-text">
                          <h2
                            id={`storyfeed-${i}`}
                            className={asset ? 'latest-header' : 'latest-header-large'} >
                            <ReactMarkdown
                              source={title}
                              renderers={{
                                text: (node) => {
                                  return `${node.value}`.replace(/(\S+)-(\S+)/g,'$1\u2013$2')
                                }
                              }}
                              escapeHtml={false} />
                          </h2>
                          {subtitle &&
                            <ReactMarkdown
                              source={` — ${subtitle}`}
                              renderers={{
                                text: (node) => {
                                  return `${node.value}`.replace(/(\S+)-(\S+)/g,'$1\u2013$2')
                                }
                              }}
                              escapeHtml={false} />
                          }
                        </div>
                      </AnchorTag>
                    </div>
                  </div>
                </Fade>
              </article>
              )
          })}
        </Masonry>
      </div>
    </div>
  )
}

export default StoryFeedGrid