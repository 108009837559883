import React from 'react';
import Layout, { buildProps } from './index.js';
import Aggregate from './main-components/Aggregate';

const Component = (props) => {
  const {
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    pages,
    pages: {
      template
    },
    categoryInformation,
    paths
  } = buildProps(props)

  const addedProps = {
    pages,
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    paths
  }
  return <Aggregate
  {...addedProps}
  isNewCategory={!!template.includes('Category')}
  categoryInformation={categoryInformation} />
}

const Page = (props) => {
  return <Layout {...props}><Component {...props} /></Layout>
}

export default Page