import React, { 
  useState,
  useContext,
  useEffect,
  useCallback
 } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { sContext } from '../../context'
import StoryFeedGrid from './StoryFeedGrid'
import ReactMarkdown from '../../lib/ReactMarkdown'
import { getLandscapeAsset } from '../../lib/getAssets'
import EntriesSection from './EntriesSection'

const AggregateStoryFeed = ({
  categoryPageData,
  isSearch
}) => {
  const localePath = useContext(sContext).currentLocale
  const location = useContext(sContext).location
  const [state, setState] = useState({
    entries: [],
    intro: categoryPageData?.title,
    text: categoryPageData?.text
  })

  const getQueryVariable = (variable) => {
    var query = location.search.substring(1)
    query = query.replace('%26', '&')
    var vars = query.split('=')
    if(vars[0] === variable) {
      return decodeURIComponent(vars[1].toLowerCase())

    } else {
      return ''
    }
  }

  const getQuery = () => {
    if(categoryPageData) {
      return ''

    } else {
      return getQueryVariable('aggTag') || getQueryVariable('q')
    }
  }

  const loadArticlesFromAlgolia = useCallback(async (userQuery = null, searchByTag = null) => {
    const query = userQuery || getQuery()
    const isTag = searchByTag === null && getQueryVariable('aggTag')
      ? true
      : !!searchByTag
    const client = algoliasearch(
      'GP0KSFPKJR',
      '318243fd350e6a3db6fc3bac67f39a35'
    ) // FRONTEND: Use a env var
    const index = client.initIndex(
      `prod_enroute_${localePath}`
    ) // FRONTEND: Use a env var
    const queryParameters = {
      facetFilters: [
        ['template:Article', 'template:CityGuide', 'template:CBNR Top 10']
      ],
      filters: 'NOT hidden:true'
    }
    if (isTag) {
      const tags = typeof query === 'string' ? [query] : query
      queryParameters.tagFilters = tags

      if(query === 'science of travel') {
        queryParameters.tagFilters.push('captain doug') 
        queryParameters.tagFilters = [queryParameters.tagFilters]

      } else if(query === 'la science du voyage') {
        queryParameters.tagFilters.push('commandant morris')
        queryParameters.tagFilters = [queryParameters.tagFilters]
      }
    }

    if (categoryPageData) {
      queryParameters.facetFilters = [`category.path:${categoryPageData.path}`]
    }

    const result = await index.search(isTag ? '' : query, queryParameters)

    let entries = []

    for (let i = 0; i < result.hits.length; i++) {
      const hit = result.hits[i];
      const {
        published_at,
        updated_at,
        article
      } = hit
      const dateSeconds = Date.now()
      /** In some articles, the published_at is undefined. It seems to be the case for older articles. */
      const publishUpdate = published_at ? Date.parse(published_at) : updated_at * 1000
      const timeSince = dateSeconds - publishUpdate
      const quarterHourMili = 900000
      if(timeSince < quarterHourMili) continue

      let entry = Object.assign({ fields: {} }, hit, article || {})

      if (hit.template === 'Article') {
        let type
        if(entry.articleType) {
          type = entry.articleType

        } else if(entry.article && entry.article.fields.type) {
          type = entry.article.fields.type

        } else if(entry._tags && entry._tags.length) {
          type = entry._tags.find(t => t.toLowerCase() !== 'latest')

        } else {
          type = 'article'
        }

        entries.push(Object.assign({}, entry, {
          fields: {
            title: entry.title,
            subtitle: entry.subtitle,
            type
          }
        }))
      }
    }

    entries = entries.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          t => t.title === item.title && t.subtitle === item.subtitle
        )
    )

    const formatedEntries = []

    for (let i = 0; i < entries.length; i++) {
      let entry = entries[i]

      if(entry.path.substring(0, 1) !== '!') {
        const pageType = categoryPageData ? categoryPageData.path : 'article'

        if(pageType === 'article' &&  entry.category?.path) {
          entry.categoryPath = entry.category.path

        } else if(pageType === 'article' &&  entry.newCategory?.path) {
            entry.categoryPath = entry.newCategory.path

        } else {
          entry.categoryPath = pageType
        }
      }


      if (entry.path && entry.path.includes('/top-10/')) {
        path = entry.path
        entry.path = '/canadas-best-new-restaurants/' + localePath
      }

      if(entry.sponsored_by) {
        entry.hasSponsor = entry.sponsored_by
        
      } else if(entry.fields.type?.includes('Air Canada enRoute')) {
        entry.hasSponsor = entry.fields.type
      }

      entry.asset = getLandscapeAsset({
        inArticleAsset: entry.inArticleAsset,
        landscapeDesktopAsset: entry.landscapeDesktopAsset,
        image: entry.image,
        topType: entry.articleTopType
      })

      formatedEntries.push({ fields: entry })
    }

    setState((prevState) => ({
      ...prevState,
      entries: formatedEntries,
      intro: prevState.intro || query,
      noEntriesText: result.hits.length
        ? ''
        : localePath === 'fr'
        ? 'Désolé, aucun résultat ne correspond à votre recherche.'
        : 'Sorry, your search yielded no results.'
    }))
  }, [categoryPageData, getQueryVariable, getQuery])

  useEffect(() => {
    loadArticlesFromAlgolia()
  }, [])

  const {
    entries,
    intro,
    text,
    noEntriesText
  } = state
  
  return (
    <div className="row latest-main search-grid">
      <div className="col-lg-12">
        {!isSearch && intro ? (
          <h1 className="row latest-title">
            {intro}
          </h1>
        ) : null}
        {text && <p>
          <ReactMarkdown source={text} />
        </p>}
        <StoryFeedGrid entries={entries} />
        {!entries.length &&
          <div
            className="latest-noarticles"
            style={{ textAlign: 'center', width: '100%', padding: '8% 0 5%' }} >
            {noEntriesText}
          </div>
        }
      </div>
      {!entries.length && 
        <EntriesSection
          isArticle
          category={'lastest'}
          entriesUsedId={[]}
          section={{
            intro: localePath === 'en' ?
              'Read More' : 'À lire',
            filterBy: 'category',
            type: 'medium-block'
          }} />
      }
    </div>
  )
}

export default AggregateStoryFeed