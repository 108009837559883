import React from 'react'
import ReactMarkdown from '../../../lib/ReactMarkdown'
import EntriesSection from '../EntriesSection'

const CityGuides = ({
  modules,
  categoryInformation
}) => {
  return (
    <div className="row cityguides-guides cityguides-fullwidth">
      <h1 className='latest-title city-guide'>
        {categoryInformation.title}
      </h1>
      {categoryInformation && (
        <ReactMarkdown source={categoryInformation.description} />
      )}

      {modules.map((module, i) => {
        const { target } = module.data

        if(!target) return null

        const {
          sys: { contentType: { sys: {
            id: sysId
          }}},
          fields: section
        } = target[0]

        if(sysId === 'EntriesSection') {
          return (
            <EntriesSection
              key={`CityGuide-Entried-${i}`}
              section={section} />
          )
        }
      })}
    </div>   
  )
}

export default CityGuides
